import Resources from '../../../resources';

export enum PaymentProviderName {
    InDriver = 'INDRIVER',
    Dlocal = 'DLOCAL',
    Credorax = 'CREDORAX',
    Slick = 'SLICK',
    Yuno = 'YUNO',
    Puntoxpress = 'PUNTOXPRESS',
    Fawry = 'FAWRY',
    KaspiKz = 'KASPI',
    QiwiKz = 'QIWIKZ',
    Fortumo = 'FORTUMO',
    Wooppay = 'WOOPPAY',
    Kassa24 = 'KASSA24',
    Payrails = 'PAYRAILS',
    Gifty = 'GIFTY',
    ImePay = 'IMEPAY',
    Iyzico = 'IYZICO',
    Aamarpay = 'AAMARPAY',
    Izi = 'IZI',
    OneVision = 'ONEVISION',
    Simpaisa = 'SIMPAISA',
    Onoi = 'ONOI',
    Nuvei = 'NUVEI',
    Bcel = 'BCEL',
}

class Providers {
    public static isPaymentProviderName(value: string): value is PaymentProviderName {
        return Object.values(PaymentProviderName).some(n => {
            return value === n;
        });
    }

    public static getBrandImg = (brand = '') => {
        let embeded = brand
            .replace(/-/gm, '')
            .replace(/\s/gm, '')
            .replace('\'', '')
            .toLowerCase();

        if (embeded.startsWith('visa')) {
            embeded = 'visa';
        }

        if (embeded.startsWith('mastercard')) {
            embeded = 'mastercard';
        }

        switch (embeded) {
            case '7eleven':
            case 'seveneleven':
                return Resources.files.providers['7-eleven'];
            case 'airtel':
                return Resources.files.providers['airtel'];
            case 'alfa':
                return Resources.files.providers['alfa'];
            case 'alfamart':
                return Resources.files.providers['alfamart'];
            case 'americanexpress':
            case 'amex':
                return Resources.files.providers['american-express'];
            case 'baloto':
                return Resources.files.providers['baloto'];
            case 'bancopopular':
            case 'bancopopulardominicano':
            case 'banco':
                return Resources.files.providers['banco-popular'];
            case 'bigc':
                return Resources.files.providers['big-c'];
            case 'boleto':
                return Resources.files.providers['boleto'];
            case 'boost':
                return Resources.files.providers['boost'];
            case 'cmr':
                return Resources.files.providers['cmr'];
            case 'codi':
                return Resources.files.providers['codi'];
            case 'easypaisa':
                return Resources.files.providers['easypaisa'];
            case 'efecty':
                return Resources.files.providers['efecty'];
            case 'fawry':
            case 'fawrypay':
                return Resources.files.providers['fawry'];
            case 'fpx':
                return Resources.files.providers['fpx'];
            case 'gcash':
                return Resources.files.providers['gcash'];
            case 'gifty':
                return Resources.files.providers['gifty'];
            case 'grabpay':
            case 'grabpaywallet':
            case 'grabpayewallet':
                return Resources.files.providers['grabpay'];
            case 'imepay':
                return Resources.files.providers['imepay'];
            case 'jazzcash':
                return Resources.files.providers['jazzcash'];
            case 'jcb':
                return Resources.files.providers['jcb'];
            case 'kaspi':
                return Resources.files.providers['kaspi'];
            case 'kassa24':
            case 'kassa_24':
                return Resources.files.providers['kassa24'];
            case 'khipu':
                return Resources.files.providers['khipu'];
            case 'konnect':
                return Resources.files.providers['konnect'];
            case 'mpesa':
                return Resources.files.providers['m-pesa'];
            case 'visa':
                return Resources.files.providers['visa'];
            case 'mastercard':
            case 'mc':
                return Resources.files.providers['mastercard'];
            case 'mercado-pago':
            case 'mercado':
                return Resources.files.providers['mercado-pago'];
            case 'nequi':
                return Resources.files.providers['nequi'];
            case 'ovo':
                return Resources.files.providers['ovo'];
            case 'okra':
                return Resources.files.providers['okra'];
            case 'opay':
                return Resources.files.providers['opay'];
            case 'oxxo':
                return Resources.files.providers['oxxo'];
            case 'paga':
                return Resources.files.providers['paga'];
            case 'pagoefectivo':
                return Resources.files.providers['pago-efectivo'];
            case 'pagofacil':
                return Resources.files.providers['pago-facil'];
            case 'pagalpaso':
                return Resources.files.providers['pagoalpaso'];
            case 'paymaya':
                return Resources.files.providers['paymaya'];
            case 'paynet':
                return Resources.files.providers['paynet'];
            case 'paytm':
            case 'paytmwallet':
                return Resources.files.providers['paytm'];
            case 'pix':
            case 'pixqrcode':
                return Resources.files.providers['pix'];
            case 'pse':
                return Resources.files.providers['pse'];
            case 'punto':
            case 'puntoxpress':
                return Resources.files.providers['puntoxpress'];
            case 'puntopago':
                return Resources.files.providers['punto-pago'];
            case 'qiwi':
            case 'qiwikz':
            case 'qiwi_kz':
                return Resources.files.providers['qiwi'];
            case 'rapipago':
                return Resources.files.providers['rapi-pago'];
            case 'shopeepay':
                return Resources.files.providers['shopeepay'];
            case 'spei':
                return Resources.files.providers['spei'];
            case 'stitch':
                return Resources.files.providers['stitch'];
            case 'thaiqr':
                return Resources.files.providers['thai-qr'];
            case 'tescolotus':
                return Resources.files.providers['tesco-lotus'];
            case 'touchgo':
            case 'touchngo':
            case 'touch&go':
                return Resources.files.providers['touch-go'];
            case 'truemoney':
                return Resources.files.providers['truemoney'];
            case 'tucan':
                return Resources.files.providers['tucan'];
            case 'upi':
                return Resources.files.providers['upi'];
            case 'wafacash':
                return Resources.files.providers['wafacash'];
            case 'webpay':
                return Resources.files.providers['webpay'];
            case 'retailoutlets':
                return Resources.files.providers['retailoutlets'];
            case 'virtualaccounts':
                return Resources.files.providers['virtualaccounts'];
            default:
                return '';
        }
    };
}

export default Providers;
