import { Button } from '@indriver/nova';
import i18n from 'i18next';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import DisplayedError from '@/lib/errors/displayed-error';
import ErrorStatus from '@/lib/errors/status';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { PaymentMethod } from '@/services/payment-info-service';

import InternalErrorPage from '../../internal-error-page';

import { MainStore } from '../index';

import BcelWalletController from './bcel-wallet/controller';
import OneVisionCheckoutController from './checkouts/onevision-checkout/controller';
import PayrailsCheckoutController from './checkouts/payrails-generic-checkout/controller';
import YunoCheckoutController from './checkouts/yuno-checkout/controller';
import CredoraxBankCardController from './credorax-bank-card/controller';
import DlocalBankCardController from './dlocal-bank-card/controller';
import DlocalBankTransferController from './dlocal-bank-transfer/controller';
import DlocalCashController from './dlocal-cash/controller';
import DlocalWalletController from './dlocal-wallet/controller';
import FawryCashController from './fawry-cash/controller';
import IyzicoBankCardController from './iyzico-bank-card/controller';
import IyzicoWalletController from './iyzico-wallet/controller';
import IziBankCardController from './izi-bank-card/controller';
import IziCashController from './izi-cash/controller';
import NuveiBankCardController from './nuvei-bank-card/controller';
import PayrailsBankCardController from './payrails-bank-card/controller';
import SimpaisaController from './simpaisa-provider-controlled/controller';
import SlickBankCardController from './slick-bank-card/controller';
import * as UI from './ui';
import WooppayPhoneTransferController from './wooppay-phone-balance/controller';
import YunoBankCardController from './yuno-bank-card/controller';

import Resources from '../../../../../resources';
import { AppData } from '../../../index';

type OptionalProviderNames = Partial<Record<PaymentProviderName, string>>;
type PaymentInfoSlugByName = Partial<Record<PaymentType, OptionalProviderNames>>;

export type PaymentInfoSlug = typeof PaymentForm.PAYMENT_INFO_SLUG[PaymentType] | undefined;

export type PaymentFormData = AppData & MainStore;

type PaymentFormProps = PaymentFormData & {
    method: PaymentMethod;
    hideGoBack: boolean;
    onGoBack: VoidFunction;
} & WithTranslation;

class PaymentForm extends Component<PaymentFormProps> {
    public static PAYMENT_INFO_SLUG = {
        BANK_CARD: {
            CREDORAX: `${PaymentType.BANK_CARD}/${PaymentProviderName.Credorax}`,
            DLOCAL: `${PaymentType.BANK_CARD}/${PaymentProviderName.Dlocal}`,
            SLICK: `${PaymentType.BANK_CARD}/${PaymentProviderName.Slick}`,
            YUNO: `${PaymentType.BANK_CARD}/${PaymentProviderName.Yuno}`,
            PAYRAILS: `${PaymentType.BANK_CARD}/${PaymentProviderName.Payrails}`,
            ONEVISION: `${PaymentType.BANK_CARD}/${PaymentProviderName.OneVision}`,
            IYZICO: `${PaymentType.BANK_CARD}/${PaymentProviderName.Iyzico}`,
            IZI: `${PaymentType.BANK_CARD}/${PaymentProviderName.Izi}`,
            NUVEI: `${PaymentType.BANK_CARD}/${PaymentProviderName.Nuvei}`,
        },
        BANK_TRANSFER: {
            DLOCAL: `${PaymentType.BANK_TRANSFER}/${PaymentProviderName.Dlocal}`,
            YUNO: `${PaymentType.BANK_TRANSFER}/${PaymentProviderName.Yuno}`,
            PAYRAILS: `${PaymentType.BANK_TRANSFER}/${PaymentProviderName.Payrails}`,
        },
        CASH: {
            DLOCAL: `${PaymentType.CASH}/${PaymentProviderName.Dlocal}`,
            FAWRY: `${PaymentType.CASH}/${PaymentProviderName.Fawry}`,
            YUNO: `${PaymentType.CASH}/${PaymentProviderName.Yuno}`,
            PAYRAILS: `${PaymentType.CASH}/${PaymentProviderName.Payrails}`,
            IZI: `${PaymentType.CASH}/${PaymentProviderName.Izi}`,
        },
        WALLET: {
            DLOCAL: `${PaymentType.WALLET}/${PaymentProviderName.Dlocal}`,
            YUNO: `${PaymentType.WALLET}/${PaymentProviderName.Yuno}`,
            PAYRAILS: `${PaymentType.WALLET}/${PaymentProviderName.Payrails}`,
            IYZICO: `${PaymentType.WALLET}/${PaymentProviderName.Iyzico}`,
        },
        TERMINAL: {},
        PHONE_BALANCE: {
            WOOPPAY: `${PaymentType.PHONE_BALANCE}/${PaymentProviderName.Wooppay}`,
            ONEVISION: `${PaymentType.PHONE_BALANCE}/${PaymentProviderName.OneVision}`,
        },
        PROVIDER_CONTROLLED: {
            SIMPAISA: `${PaymentType.PROVIDER_CONTROLLED}/${PaymentProviderName.Simpaisa}`,
            BCEL: `${PaymentType.PROVIDER_CONTROLLED}/${PaymentProviderName.Bcel}`,
        },
    } satisfies PaymentInfoSlugByName;

    private getPaymentInfoSlug = (method: PaymentMethod): PaymentInfoSlug => {
        const {
            paymentType,
            providerName,
        } = method;

        const routers: PaymentInfoSlugByName = PaymentForm.PAYMENT_INFO_SLUG;

        return routers[paymentType]?.[providerName];
    };

    private renderForm() {
        const { props } = this;

        const slug = this.getPaymentInfoSlug(props.method);

        switch (slug) {
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.CREDORAX:
                return <CredoraxBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.DLOCAL:
                return <DlocalBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.SLICK:
                return <SlickBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.YUNO:
                return <YunoBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.IZI:
                return <IziBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.IYZICO:
                return <IyzicoBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.PAYRAILS:
                return <PayrailsBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.NUVEI:
                return <NuveiBankCardController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_CARD.ONEVISION:
                return <OneVisionCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_TRANSFER.DLOCAL:
                return <DlocalBankTransferController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_TRANSFER.YUNO:
                return <YunoCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.BANK_TRANSFER.PAYRAILS:
                return <PayrailsCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.CASH.DLOCAL:
                return <DlocalCashController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.CASH.FAWRY:
                return <FawryCashController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.CASH.YUNO:
                return <YunoCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.CASH.PAYRAILS:
                return <PayrailsCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.CASH.IZI:
                return <IziCashController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.WALLET.DLOCAL:
                return <DlocalWalletController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.WALLET.YUNO:
                return <YunoCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.WALLET.PAYRAILS:
                return <PayrailsCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.WALLET.IYZICO:
                return <IyzicoWalletController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.PHONE_BALANCE.WOOPPAY:
                return <WooppayPhoneTransferController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.PHONE_BALANCE.ONEVISION:
                return <OneVisionCheckoutController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.PROVIDER_CONTROLLED.SIMPAISA:
                return <SimpaisaController {...props} />;
            case PaymentForm.PAYMENT_INFO_SLUG.PROVIDER_CONTROLLED.BCEL:
                return <BcelWalletController {...props} />;
            default:
                return (
                    <InternalErrorPage
                        navigatorService={props.services.navigatorService}
                        error={
                            new DisplayedError({
                                title: i18n.t('lib.errors.unsupportedPaymentMethodLabel'),
                                messages: [i18n.t('lib.errors.unsupportedPaymentMethodMessage',
                                    { method: slug })],
                                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR
                            })
                        }
                    />
                );
        }
    }

    public render() {
        return (
            <>
                <UI.Wrapper>
                    {this.renderForm()}
                </UI.Wrapper>

                {
                    !this.props.hideGoBack ? (
                        <UI.GoBackWrapper>
                            <Button
                                data-id={Resources.test.backButton}
                                design='secondary'
                                type='submit'
                                onClick={this.props.onGoBack}
                            >
                                {this.props.t('shared.ui.domain.SubmitForm.goBackCaption')}
                            </Button>
                        </UI.GoBackWrapper>

                    ) : null
                }
            </>
        );
    }
}

export default withTranslation()(PaymentForm);
