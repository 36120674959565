import { Countries } from '@/lib/locale/countries';

export type PaymentMethodData = {
    is_customer_saved: boolean;
    is_removable: boolean;
    method_id: number;
    method_name: string;
    payment_type: string;
    provider: string;
    provider_method_code: string;
    session?: {
        yuno?: {
            apiKey: string
            session: string;
            token: string;
        };
        gifty?: {
            reference_number: string;
        };
        imepay?: {
            redirect_url: string;
            reference_number: string;
        };
        payrails?: {
            instrument_id: string;
            payment_id: string;
            session: string;
        };
        dlocal?: {
            api_key: string;
        };
        credorax?: {
            staticKey: string;
            merchantID: string;
        };
        puntoxpress?: {
            reference_number: string;
        }
        qiwi_kz?: {
            reference_number: string
        }
        kassa24?: {
            reference_number: string
        },
        kaspi?: {
            reference_number: string
        }
        onoi?: {
            reference_number: string
        }
    };
    limits: {
        min: number;
        max: number;
    },
    saved_instruments: {
        bank_card?: {
            bank_card_id: string;
            last4: string;
        }[];
    };
}

export enum SuggestionViewType {
    REGULAR = 'regular',
    WITH_RIDES = 'with_rides',
    WITH_DAYS = 'with_days'
}

type SuggestionData = {
    value: number;
    days: number;
    rides: number;
};

export type SuggestedAmountViewData = {
    view_type?: SuggestionViewType;
    suggested_amounts: SuggestionData[];
};

export type PaymentInfoData = {
    country_id: Countries;
    payment_methods?: PaymentMethodData[];
    suggested_amounts_view: SuggestedAmountViewData;
    fill_form: {
        email?: string;
        document?: string;
        phone?: string;
        address?: string;
        billingAddress?: string;
    },
    auto_topup?: {
        enable: boolean,
        threshold_suggests: number[],
        amount_suggests: number[],
        user_settings: {
            enabled: boolean,
            balance_threshold: number,
            amount: number,
            saved_card: {
                uuid: string,
                last4: string,
                token: string
            }
        }
    }
}
