import { gql } from '@apollo/client';

export type TopupWithBCELInput = {
    amount: number;
    email: string;
    state?: string;
    city?: string;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
};

export type TopupWithBCELResponse = {
    topupWithBCEL: {
        parameters: {
            key: string;
            value: string;
        }[];
    };
};

export type TopupWithBCELMutation = {
    input: TopupWithBCELInput;
};

export default gql(`
    mutation topupWithBCEL($input: TopupWithBCELInput!) {
        topupWithBCEL(input: $input) {
            parameters {
                key
                value
            }
        }
    }
`);
