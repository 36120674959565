import { FC, useCallback } from 'react';

import FormFieldView from '@/features/form-field-view';
import { FormValues } from '@/lib/payments/forms';

import { PaymentFormData } from '../index';

const BcelWalletController: FC<PaymentFormData> = ({
    paymentInfo,
    services,
    store,
}) => {
    const handleSubmit = useCallback((values: FormValues) => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            values,
            paymentInfo.country,
        );
    }, []);

    return (
        <FormFieldView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmit}
        />
    );
};

export default BcelWalletController;
