import i18n from 'i18next';

const R = '/static/images';
const P = '/providers';

class Resources {
    static SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
    static CREDORAX_PKEY_URL = process.env.REACT_APP_CREDORAX_PKEY_URL || '';
    static BCEL_ENDPOINT_URL = process.env.REACT_APP_BCEL_ENDPOINT_URL || '';
    static SWERVE_SDK_API_KEY = process.env.REACT_APP_SWERVE_SDK_API_KEY || '';
    static DLOCAL_SDK_URL = process.env.REACT_APP_DLOCAL_SDK_URL || '';
    static DLOCAL_API_KEY = {
        DEFAULT: process.env.REACT_APP_DLOCAL_API_KEY_DEFAULT || '',
        CASHLESS: {
            BRAZIL: process.env.REACT_APP_DLOCAL_API_KEY_CASHLESS_BRAZIL || '',
        }
    };
    static YUNO_SDK = process.env.REACT_APP_YUNO_SDK || '';
    static CASHLESS_LOADER_URL = process.env.REACT_APP_CASHLESS_LOADER_URL || '';
    static INDRIVER_SECURE_SDK = process.env.REACT_APP_INDRIVER_SECURE_SDK || '';

    static NUVEI_SDK = {
        URL: process.env.REACT_APP_NUVEI_SDK_URL || '',
        MERCHANT_ID: process.env.REACT_APP_NUVEI_MERCHANT_ID || '',
        MERCHANT_SITE_ID: process.env.REACT_APP_NUVEI_MERCHANT_SITE_ID || '',
    };

    static getDir = (): 'ltr' | 'rtl' => i18n.dir();
    static isRtl = (): boolean => Resources.getDir() === 'rtl';

    static files = {
        providers: {
            tengoLogo: `${R}${P}/tengo_logo.svg`,
            akisiLogo: `${R}${P}/akisi_logo.svg`,
            ['7-eleven']: `${R}${P}/7-eleven.svg`,
            ['airtel']: `${R}${P}/airtel.svg`,
            ['alfa']: `${R}${P}/alfa.svg`,
            ['alfamart']: `${R}${P}/alfamart.svg`,
            ['american-express']: `${R}${P}/american-express.svg`,
            ['baloto']: `${R}${P}/baloto.svg`,
            ['banco-popular']: `${R}${P}/banco-popular.svg`,
            ['big-c']: `${R}${P}/big-c.svg`,
            ['boleto']: `${R}${P}/boleto.svg`,
            ['boost']: `${R}${P}/boost.svg`,
            ['cmr']: `${R}${P}/cmr.svg`,
            ['codi']: `${R}${P}/codi.svg`,
            ['easypaisa']: `${R}${P}/easypaisa.svg`,
            ['efecty']: `${R}${P}/efecty.svg`,
            ['fawry']: `${R}${P}/fawry.svg`,
            ['fpx']: `${R}${P}/fpx.svg`,
            ['gcash']: `${R}${P}/gcash.svg`,
            ['grabpay']: `${R}${P}/grabpay.svg`,
            ['imepay']: `${R}${P}/imepay.svg`,
            ['jazzcash']: `${R}${P}/jazzcash.svg`,
            ['gifty']: `${R}${P}/gifty.svg`,
            ['jcb']: `${R}${P}/jcb.svg`,
            ['kaspi']: `${R}${P}/kaspi.svg`,
            ['kassa24']: `${R}${P}/kassa24.svg`,
            ['khipu']: `${R}${P}/khipu.svg`,
            ['konnect']: `${R}${P}/konnect.png`,
            ['m-pesa']: `${R}${P}/m-pesa.svg`,
            ['visa']: `${R}${P}/visa.svg`,
            ['mastercard']: `${R}${P}/mastercard.svg`,
            ['mercado-pago']: `${R}${P}/mercado-pago.svg`,
            ['nequi']: `${R}${P}/nequi.svg`,
            ['ovo']: `${R}${P}/ovo.svg`,
            ['okra']: `${R}${P}/okra.svg`,
            ['paga']: `${R}${P}/paga.svg`,
            ['opay']: `${R}${P}/opay.svg`,
            ['oxxo']: `${R}${P}/oxxo.svg`,
            ['pago-efectivo']: `${R}${P}/pago-efectivo.svg`,
            ['pago-facil']: `${R}${P}/pago-facil.svg`,
            ['pagoalpaso']: `${R}${P}/pagoalpaso.svg`,
            ['paymaya']: `${R}${P}/paymaya.svg`,
            ['paynet']: `${R}${P}/paynet.svg`,
            ['paytm']: `${R}${P}/paytm.svg`,
            ['pix']: `${R}${P}/pix.svg`,
            ['pse']: `${R}${P}/pse.png`,
            ['puntoxpress']: `${R}${P}/puntoxpress.svg`,
            ['punto-pago']: `${R}${P}/punto-pago.svg`,
            ['qiwi']: `${R}${P}/qiwi.svg`,
            ['rapi-pago']: `${R}${P}/rapi-pago.svg`,
            ['retailoutlets']: `${R}${P}/retailoutlets.png`,
            ['shopeepay']: `${R}${P}/shopeepay.svg`,
            ['spei']: `${R}${P}/spei/svg`,
            ['stitch']: `${R}${P}/stitch.svg`,
            ['thai-qr']: `${R}${P}/thai-qr.svg`,
            ['tesco-lotus']: `${R}${P}/tesco-lotus.svg`,
            ['touch-go']: `${R}${P}/touch-go.svg`,
            ['truemoney']: `${R}${P}/truemoney.svg`,
            ['tucan']: `${R}${P}/tucan.svg`,
            ['upi']: `${R}${P}/upi.svg`,
            ['virtualaccounts']: `${R}${P}/virtualaccounts.png`,
            ['wafacash']: `${R}${P}/wafacash.svg`,
            ['webpay']: `${R}${P}/webpay.svg`,
        },
        cvcHelper: `${R}/cvc-helper.svg`,
        cardtype: {
            visa: `${R}/cardtype/visa.svg`,
            maestro: `${R}/cardtype/maestro.svg`,
            discover: `${R}/cardtype/discover.svg`,
            americanExpress: `${R}/cardtype/american-express.svg`,
            mastercard: `${R}/cardtype/mastercard.svg`,
            mir: `${R}/cardtype/mir.svg`,
            amex: `${R}/cardtype/amex.svg`,
            jcb: `${R}/cardtype/jcb.svg`,
            dinersclub: `${R}/cardtype/dinersclub.svg`,
            default: `${R}/cardtype/default.svg`,
        },
        paymenttype: {
            bankTransfer: `${R}/paymenttype/bank-transfer.svg`,
            cash: `${R}/paymenttype/cash.svg`,
        },
        documents: {
            default: `${R}/documents/default.png`,
            kenya: `${R}/documents/kenya.png`,
            bolivia: `${R}/documents/bolivia.png`,
            argentina: `${R}/documents/argentina.png`,
            colombia: `${R}/documents/colombia.png`,
            southafrica: `${R}/documents/southafrica.png`,
            costarica: `${R}/documents/costarica.png`,
            brazil: `${R}/documents/brazil.png`,
            ecuador: `${R}/documents/ecuador.png`,
            dominicanrepublic: `${R}/documents/dominicanrepublic.png`,
            malaysia: `${R}/documents/malaysia.png`,
            panama: `${R}/documents/panama.png`,
            nigeria: `${R}/documents/nigeria.png`,
            pakistan: `${R}/documents/pakistan.png`,
            nicaragua: `${R}/documents/nicaragua.png`,
            thailand: `${R}/documents/thailand.png`,
            india: `${R}/documents/india.png`,
            peru: `${R}/documents/peru.png`,
            egypt: `${R}/documents/egypt.png`,
            indonesia: `${R}/documents/indonesia.png`,
            chile: `${R}/documents/chile.png`,
            mexico: `${R}/documents/mexico.png`,
            guatemala: `${R}/documents/guatemala.png`,
            honduras: `${R}/documents/honduras.png`,
            morocco: `${R}/documents/morocco.png`,
            kazakhstan: `${R}/documents/kazakhstan.png`,
            tanzania: `${R}/documents/tanzania.png`,
            algeria: `${R}/documents/algeria.png`,
            zimbabwe: `${R}/documents/zimbabwe.png`,
            turkey: `${R}/documents/turkey.png`,
        },
        defaultProvider: `${R}/default-provider.png`,
    };
    static test = {
        checkbox: 'checkbox',
        backButton: 'back-button',
        submitHint: 'submit-hint',
        submit: 'submit',
        submitCVV: 'submit-cvv',
        iframeMap: 'iframe-map',
        paycodeViewButtonCopied: 'paycode-view-button-copied',
        paycodeViewButtonCopy: 'paycode-view-button-copy',
        paycode: 'paycode',
        bottomSheetClose: 'bottom-sheet-close',
        toggleDeleteModalButton: 'toggle-delete-modal-button',
        removeDeleteModalButton: 'remove-delete-modal-button',
        moreBottomSheetButton: 'more-bottom-sheet-button',
        existedCard: 'existed-card',
        bottomSheetHeading: 'bottom-sheet-heading',
        paymentMethodSelect: 'payment-method-select',
        paymentMethodItem: 'payment-method-item',
        selectedPaymentMethodItem: 'selected-payment-method-item',
        paymentMethodSelectTitle: 'payment-method-select-title',
        paymentMethodSelectDescription: (highlighted?: boolean) => {
            const id = 'payment-method-select-description';

            return highlighted ? id + '-highlighted' : id;
        },
        chipButton: 'chip-button',
        howToTopUpForm: {
            title: 'howtotopupform-title',
            points: 'howtotopupform-points',
            email: 'howtotopupform-email',
            chat: 'howtotopupform-chat',
            support: 'howtotopupform-support',
            phone: 'howtotopupform-phone',
        },
        autoTopup: {
            amountChipButton: 'amount-chip-button',
            selectedAmountChipButton: 'selected-amount-chip-button',
            thresholdChipButton: 'threshold-chip-button',
            selectedThresholdChipButton: 'selected-threshold-chip-button',
            submit: 'submit',
            disable: 'disable',
            autoTopupStatus: 'auto-topup-status',
            autoTopup: 'auto-topup',
        },
        requestAuth: 'request-auth',
    };
    static contacts = {
        mainSupportEmail: 'support@indrive.com',
    };
}

export default Resources;
