import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import { Text12Medium, Text16Medium } from '@/shared/typography/text';

export const ChipsWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    width: 100%;
`;

export const ChipsEmptySpace = styled.div<{ $show: boolean }>`
    ${props => props.$show && css`
        margin-bottom: 12px;
    `}
`;

export const Chip = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    border-radius: 16px;
    transition: all 0.2s;
    height: 48px;
    background: ${variables['background-secondary']};
    flex: 1;
    justify-content: center;
    align-items: center;
`;

export const Amount = styled.span`
    ${Text16Medium};
`;

export const Description = styled.span`
    ${Text12Medium};
    color: ${variables['text-and-icon-secondary']};
`;
