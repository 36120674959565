import { FC } from 'react';

import FormFieldView from '@/features/form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { FormValues } from '@/lib/payments/forms';

import { PaymentFormData } from '../../index';

const OneCheckoutController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const handleSubmitPayment = async (
        values: FormValues,
    ): Promise<GQLErrorType> => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            values,
            paymentInfo.country,
        );
    };

    return (
        <FormFieldView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmitPayment}
        />
    );
};

export default OneCheckoutController;
