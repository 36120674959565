import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import {
    Display48Bold,
    Text16Bold,
    Text16Medium
} from '../../../typography/text';

export const Wrapper = styled.div``;

export const AmountTitle = styled.div`
  ${Text16Bold};

  color: ${variables['text-and-icon-primary']};
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputContent = styled.div`
  width: 100%;
  padding-bottom: 1px;
  border-bottom: 1px solid ${variables['border-primary']};
  position: relative;
  display: flex;
`;

export const Input = styled.input<{ $error: boolean; }>`
  ${Display48Bold};

  ${props => props.$error && css`
    color: ${variables['extensions-text-and-icon-error']};
    margin-bottom: 31px;
  `}
  
  background: ${variables['background-primary']};
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
  caret-color: ${variables['extensions-background-inverseprimary']};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield; /* Firefox */
`;

export const Error = styled.div`
  ${Text16Medium};
  width: 100%;
  color: ${variables['extensions-text-and-icon-error']};
  
  margin-top: 8px;
`;