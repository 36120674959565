import { ClearOutline, DoneFilled } from '@indriver/mireska';
import { Button, Cell } from '@indriver/nova';
import { Component } from 'react';
import {
    WithTranslation,
    withTranslation
} from 'react-i18next';

import DisplayedError from '@/lib/errors/displayed-error';
import Price from '@/lib/format/price';
import { CurrencyCode } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import { AutoTopupInfo, SavedAutoTopup } from '@/services/payment-info-service';
import Modal from '@/shared/ui/core/modal';
import PaymentMethodSelect from '@/shared/ui/domain/payment-method-select';
import ChevronArrow from '@/shared/ui/icons/arrow';
import PaymentMethodIcon from '@/shared/ui/icons/payment-method';
import RoundedSections from '@/shared/ui/layout/rounded-sections';

import * as UI from './ui';

import Resources from '../../../../../resources';
import { AppData } from '../../../index';

type AutoTopupSettingsProps = {
    country: Countries;
    currencyCode: CurrencyCode;
    autoTopupInfo: AutoTopupInfo;
    services: AppData['services'];
    onGoBack: VoidFunction;
} & WithTranslation;

type AutoTopupSettingsState = {
    loading: boolean;
    showPaymentMethods: boolean;
    selectedMethodIndex: number;
    amount: number | null;
    theresold: number | null;
}

type ActionButtonTypes = 'save' | 'enable' | 'disable';

class AutoTopupSettingsForm extends Component<AutoTopupSettingsProps, AutoTopupSettingsState> {
    constructor(props: AutoTopupSettingsProps) {
        super(props);

        this.state = {
            loading: false,
            showPaymentMethods: false,
            selectedMethodIndex: this.setDefaultIndex(),
            amount: this.setDefaultAmount(),
            theresold: this.setDefaultTheresold(),
        };
    }

    public componentDidMount = () => {
        this.props.services.analyticService.event('processing.topup_auto.view', undefined);
    };

    private setDefaultIndex = () => {
        const { autoTopupInfo } = this.props;
        if (!autoTopupInfo.visible) {
            return 0;
        }

        const paymentMethodItems = this.getPaymentMethodItems();

        for (let i = 0; i < paymentMethodItems.length; i++) {
            const { originalMethod } = paymentMethodItems[i];

            const token = originalMethod.session?.yuno?.vaultedToken;
            const tokenAutoTopup = autoTopupInfo.userSettings.savedCard?.token;
            if (token === tokenAutoTopup) {
                return i;
            }
        }

        return 0;
    };

    private setDefaultAmount = () => {
        const { autoTopupInfo } = this.props;
        if (!autoTopupInfo.visible) {
            return null;
        }

        const amount = autoTopupInfo.userSettings.amount;

        return autoTopupInfo.amountSuggests.includes(amount) ?
            amount : autoTopupInfo.amountSuggests[0];
    };

    private setDefaultTheresold = () => {
        const { autoTopupInfo } = this.props;
        if (!autoTopupInfo.visible) {
            return null;
        }

        const balanceTheresold = autoTopupInfo.userSettings.balanceThreshold;

        return autoTopupInfo.thresholdSuggests.includes(balanceTheresold) ?
            balanceTheresold : autoTopupInfo.thresholdSuggests[0];
    };

    private getPaymentMethods = () => {
        const { paymentInfoService } = this.props.services;

        return paymentInfoService.getYunoSavedCards();
    };

    private getPaymentMethodItems = () => {
        const { viewService } = this.props.services;
        const paymentMethods = this.getPaymentMethods();

        return viewService.getAutoTopupMethods(paymentMethods, this.props.country);
    };

    private handleMethodSelect = () => {
        const paymentMethodItems = this.getPaymentMethodItems();

        if (paymentMethodItems.length > 1) {
            this.setState({ showPaymentMethods: true });
        }
    };

    public saveAutoTopupSettings = async (
        input: SavedAutoTopup,
    ) => {
        try {
            await this.props.services.paymentProviderService.saveAutoTopup(input);
            this.props.services.navigatorService.goHome();
        } catch (e) {
            if (e instanceof DisplayedError) {
                this.props.services.navigatorService.showFinalPage('error', {
                    code: e.title,
                    message: e.messages[0],
                    errorStatus: e.errorStatus,
                });
            }
        }
    };

    private handleAnalytics = (action: ActionButtonTypes, enable: boolean) => {
        const {
            selectedMethodIndex,
            amount,
            theresold
        } = this.state;
        const paymentMethodItems = this.getPaymentMethodItems();
        const payload = {
            amount: `${amount}`,
            balance_threshold: `${theresold}`,
            enable,
            payment_option: paymentMethodItems[selectedMethodIndex].originalMethod.paymentType,
            provider: paymentMethodItems[selectedMethodIndex].originalMethod.providerName
        };

        if (action === 'save') {
            this.props.services.analyticService.event('processing.topup_auto_save.click', payload);

            return;
        }
        if (action === 'enable') {
            this.props.services.analyticService.event('processing.topup_auto_enable.click', payload);

            return;
        }
        if (action === 'disable') {
            this.props.services.analyticService.event('processing.topup_auto_disable.click', payload);

            return;
        }
    };

    private handleFetch = async (enable: boolean) => {
        const {
            selectedMethodIndex,
            amount,
            theresold,
        } = this.state;

        const paymentMethodItems = this.getPaymentMethodItems();

        const selectedMethod = paymentMethodItems[selectedMethodIndex];

        if (!amount || !theresold || !selectedMethod.originalMethod.session?.yuno?.vaultedToken) {
            return;
        }

        const input: SavedAutoTopup = {
            enable,
            amount: amount,
            balance_threshold: theresold,
            card: {
                token: selectedMethod.originalMethod.session?.yuno?.vaultedToken
            }
        };

        this.setState({
            loading: true,
        });

        await this.saveAutoTopupSettings(input);

        this.setState({
            loading: false,
        });
    };

    private handleEnable = async () => {
        this.handleAnalytics('enable', true);
        await this.handleFetch(true);
    };

    private handleSave = async () => {
        this.handleAnalytics('save', true);
        await this.handleFetch(true);
    };

    private handleDisable = async () => {
        this.handleAnalytics('disable', false);
        await this.handleFetch(false);
    };

    private handleTheresoldClick = (theresold: number) => {
        this.setState({
            theresold: theresold,
        });
    };

    private handleAmountSuggestClick = (amount: number) => {
        this.setState({
            amount: amount,
        });
    };

    private handlePaymentMethodSelect = (index: number) => {
        this.setState({
            showPaymentMethods: false,
            selectedMethodIndex: index
        });
    };

    private handleBottomSheetClose = () => {
        this.setState({
            showPaymentMethods: false,
        });
    };

    private renderChips = (autoTopupInfo: AutoTopupInfo) => {
        if (!autoTopupInfo.visible) {
            return null;
        }

        const {
            amount,
            theresold,
            selectedMethodIndex,
        } = this.state;

        const paymentMethodItems = this.getPaymentMethodItems();
        const selectedMethod = paymentMethodItems[selectedMethodIndex];

        return (
            <UI.SettingsWrapper>
                <UI.Setting>
                    <UI.SettingHeader>
                        {this.props.t('views.AutoTopupSettingsForm.stepThresholdLabel')}
                    </UI.SettingHeader>

                    <UI.Chips>
                        {
                            autoTopupInfo.thresholdSuggests.map((value) => (
                                <UI.Chip
                                    key={value}
                                    data-id={
                                        value === theresold ?
                                            Resources.test.autoTopup.selectedThresholdChipButton :
                                            Resources.test.autoTopup.thresholdChipButton
                                    }
                                    $active={value === theresold}
                                    onClick={() => this.handleTheresoldClick(value)}
                                >
                                    {new Price(value, this.props.currencyCode).format()}
                                </UI.Chip>
                            ))
                        }
                    </UI.Chips>
                </UI.Setting>

                <UI.Setting>
                    <UI.SettingHeader>
                        {this.props.t('views.AutoTopupSettingsForm.stepAmountLabel')}
                    </UI.SettingHeader>

                    <UI.Chips>{
                        autoTopupInfo.amountSuggests.map((value) => (
                            <UI.Chip
                                key={value}
                                data-id={
                                    value === amount ?
                                        Resources.test.autoTopup.selectedAmountChipButton :
                                        Resources.test.autoTopup.amountChipButton
                                }
                                $active={value === amount}
                                onClick={() => this.handleAmountSuggestClick(value)}
                            >
                                {new Price(value, this.props.currencyCode).format()}
                            </UI.Chip>
                        ))
                    }
                    </UI.Chips>
                </UI.Setting>

                <UI.Setting>
                    <UI.SettingHeader>
                        {this.props.t('views.AutoTopupSettingsForm.chargeFromCard')}
                    </UI.SettingHeader>

                    <div data-id={Resources.test.paymentMethodSelect}>
                        <UI.PaymentMethodSelect
                            title={
                                selectedMethod.name ??
                                this.props.t('views.AutoTopupSettingsForm.selectedMethodTitle')
                            }
                            description={this.props.t('features.PaymentMethodNavigation.defaultTitle')}
                            icon={
                                <PaymentMethodIcon
                                    method={selectedMethod.originalMethod}
                                />
                            }
                            onClick={this.handleMethodSelect}
                        >
                            {
                                paymentMethodItems.length === 1 ?
                                    <></>
                                    :
                                    <ChevronArrow size={20} />
                            }
                        </UI.PaymentMethodSelect>
                    </div>
                </UI.Setting>
            </UI.SettingsWrapper>
        );
    };

    public render() {
        const {
            showPaymentMethods,
            selectedMethodIndex,
            amount,
            theresold,
            loading,
        } = this.state;

        const paymentMethodItems = this.getPaymentMethodItems();
        const selectedMethod = paymentMethodItems[selectedMethodIndex];

        const { autoTopupInfo } = this.props;
        if (autoTopupInfo.visible === false) {
            return null;
        }

        const isTopupDisabled = !autoTopupInfo.userSettings || autoTopupInfo.userSettings.enabled === false;
        const shouldDisableButtons = !amount || !theresold || !selectedMethod || loading;

        return (
            <>
                <RoundedSections
                    top={
                        <UI.TopContent>
                            <UI.Title>
                                {this.props.t('views.AutoTopupSettingsForm.title')}
                            </UI.Title>

                            {
                                isTopupDisabled ? (
                                    <>

                                        <UI.SubTitle>
                                            {this.props.t('views.AutoTopupSettingsForm.description')}
                                        </UI.SubTitle>


                                        <UI.DoneList>
                                            <li>
                                                <DoneFilled size={24} />
                                                {this.props.t('views.AutoTopupSettingsForm.disableChargesCheckbox')}
                                            </li>

                                            <li>
                                                <DoneFilled size={24} />
                                                {this.props.t('views.AutoTopupSettingsForm.noHiddenFeesCheckbox')}
                                            </li>
                                        </UI.DoneList>
                                    </>
                                ) : null
                            }

                            {
                                !isTopupDisabled ? this.renderChips(autoTopupInfo) : null
                            }
                        </UI.TopContent>
                    }
                    bottom={
                        <UI.BottomContent>
                            <UI.FlexSpacer>
                                {
                                    isTopupDisabled ? (
                                        <UI.UnsettedSettingsWrapper>
                                            {this.renderChips(autoTopupInfo)}
                                        </UI.UnsettedSettingsWrapper>
                                    ) : null
                                }

                                {
                                    !isTopupDisabled ? (
                                        <Cell
                                            data-id={Resources.test.autoTopup.disable}
                                            dir={Resources.getDir()}
                                            prefix={<ClearOutline size={24} />}
                                            size='m'
                                            design='error'
                                            as='button'
                                            onClick={this.handleDisable}
                                            loading={loading}
                                            disabled={shouldDisableButtons}
                                        >
                                            {this.props.t('views.AutoTopupSettingsForm.button.disableLabel')}
                                        </Cell>

                                    ) : null
                                }
                            </UI.FlexSpacer>

                            <UI.ButtonGroup>
                                <Button
                                    data-id={Resources.test.autoTopup.submit}
                                    type='submit'
                                    onClick={isTopupDisabled ? this.handleEnable : this.handleSave}
                                    disabled={shouldDisableButtons}
                                    loading={loading}
                                >
                                    {
                                        isTopupDisabled ?
                                            this.props.t('views.AutoTopupSettingsForm.button.enableLabel') :
                                            this.props.t('views.AutoTopupSettingsForm.button.saveLabel')

                                    }
                                </Button>

                                <UI.GoBackWrapper>
                                    <Button
                                        data-id={Resources.test.backButton}
                                        design='secondary'
                                        type='submit'
                                        onClick={this.props.onGoBack}
                                    >
                                        {this.props.t('shared.ui.domain.SubmitForm.goBackCaption')}
                                    </Button>
                                </UI.GoBackWrapper>
                            </UI.ButtonGroup>
                        </UI.BottomContent>
                    }
                />

                <Modal
                    title={this.props.t('views.AutoTopupSettingsForm.methodListTitle')}
                    rootElementId='autotopup-method-list-root-element'
                    open={showPaymentMethods}
                    onClose={this.handleBottomSheetClose}
                >
                    <UI.MethodListWrapper>
                        {
                            paymentMethodItems.map((method, index) => {
                                const selectedPaymentMethodItem = Resources.test.selectedPaymentMethodItem;
                                const paymentMethodItem = Resources.test.paymentMethodItem;
                                const dataId = selectedMethodIndex === index ?
                                    selectedPaymentMethodItem :
                                    paymentMethodItem;

                                return (
                                    <UI.MethodListItem
                                        key={index}
                                        selected={selectedMethodIndex === index}
                                        data-id={dataId}
                                    >
                                        <PaymentMethodSelect
                                            onClick={() => this.handlePaymentMethodSelect(index)}
                                            title={method.name}
                                            icon={method.icon}
                                        />
                                    </UI.MethodListItem>
                                );
                            })
                        }
                    </UI.MethodListWrapper>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(AutoTopupSettingsForm);
