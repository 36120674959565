import * as Sentry from '@sentry/react';

import LogsService from '@/services/logs-service';

import { PaymentProviderName } from '../providers';

import Resources from '../../../resources';

type VendorSdkMap = {
    [PaymentProviderName.InDriver]: typeof window.indriveSecure.collect;
    [PaymentProviderName.Dlocal]: typeof window.dlocal;
    [PaymentProviderName.Yuno]: typeof window.Yuno.initialize;
    [PaymentProviderName.Payrails]: any;
    [PaymentProviderName.Nuvei]: typeof window.SafeCharge;
}

class SDK {
    private logsService: LogsService;

    constructor(logsService: LogsService) {
        this.logsService = logsService;
    }

    static sources: Record<PaymentProviderName, string> = {
        [PaymentProviderName.InDriver]: Resources.INDRIVER_SECURE_SDK,
        [PaymentProviderName.Dlocal]: Resources.DLOCAL_SDK_URL,
        [PaymentProviderName.Credorax]: '',
        [PaymentProviderName.Slick]: '',
        [PaymentProviderName.Yuno]: Resources.YUNO_SDK,
        [PaymentProviderName.Puntoxpress]: '',
        [PaymentProviderName.Fawry]: '',
        [PaymentProviderName.KaspiKz]: '',
        [PaymentProviderName.QiwiKz]: '',
        [PaymentProviderName.Fortumo]: '',
        [PaymentProviderName.Wooppay]: '',
        [PaymentProviderName.Kassa24]: '',
        [PaymentProviderName.Payrails]: '',
        [PaymentProviderName.Gifty]: '',
        [PaymentProviderName.ImePay]: '',
        [PaymentProviderName.Iyzico]: '',
        [PaymentProviderName.Aamarpay]: '',
        [PaymentProviderName.Izi]: '',
        [PaymentProviderName.OneVision]: '',
        [PaymentProviderName.Simpaisa]: '',
        [PaymentProviderName.Onoi]: '',
        [PaymentProviderName.Nuvei]: Resources.NUVEI_SDK.URL,
        [PaymentProviderName.Bcel]: '',
    };

    private getSdkResolver = (vendor: PaymentProviderName): () => boolean => {
        switch (vendor) {
            case PaymentProviderName.InDriver:
                return () => Boolean(window?.indriveSecure?.apiReady);
            case PaymentProviderName.Nuvei:
                return () => Boolean(window?.SafeCharge);
            case PaymentProviderName.Dlocal:
                return () => Boolean(window?.dlocal);
            case PaymentProviderName.Yuno:
                return () => Boolean(window?.Yuno?.initialize);
            default:
                return () => true;
        }
    };

    private waitFor = (vendor: PaymentProviderName, fn: () => boolean, periodCheck = 150): Promise<boolean> =>
        new Promise((resolve, reject) => {
            const timeoutOfRefresh = setTimeout(() => {
                reject(`Timeout of SDK ${vendor}`);
            }, 59_000);

            const repeat = (count = 0): void => {
                if (fn()) {
                    clearTimeout(timeoutOfRefresh);
                    resolve(true);

                    return;
                }

                setTimeout(() => {
                    repeat(count + 1);
                }, periodCheck);
            };

            repeat();
        });

    private getSdkAwaiter = (vendor: PaymentProviderName): Promise<boolean> => {
        return this.waitFor(vendor, this.getSdkResolver(vendor));
    };

    private appendScript = (vendor: PaymentProviderName): void => {
        const scriptSrc = SDK.sources[vendor] || '';

        const alreadyInjected = document.head.querySelector(`script[src="${scriptSrc}"]`);
        if (alreadyInjected) {
            return;
        }

        const scriptNode = window.document.createElement('script');
        scriptNode.src = scriptSrc;
        scriptNode.async = true;

        const firstScriptNode = window.document.head.querySelector('script');

        if (firstScriptNode?.parentNode) {
            firstScriptNode.parentNode.insertBefore(scriptNode, firstScriptNode);
        }
    };

    public async get<K extends keyof VendorSdkMap>(vendor: K): Promise<VendorSdkMap[K]> {
        if (vendor === PaymentProviderName.Payrails) {
            return await import('@payrails/web-sdk');
        }

        this.appendScript(vendor);

        const isReady = await this.getSdkAwaiter(vendor);

        if (!isReady) {
            const msg = `${vendor} sdk is not ready`;
            this.logsService.write(msg);
            Sentry.captureMessage(msg);
        }

        switch (vendor) {
            case PaymentProviderName.InDriver:
                return window.indriveSecure.collect;
            case PaymentProviderName.Dlocal:
                return window.dlocal;
            case PaymentProviderName.Nuvei:
                return window.SafeCharge;
            case PaymentProviderName.Yuno:
                return window.Yuno.initialize.bind(window.Yuno);
            default:
                return null;
        }
    }
}

export default SDK;
