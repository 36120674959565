import { Button } from '@indriver/nova';
import * as Sentry from '@sentry/react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import AutoTopupButton from '@/features/auto-topup-button';
import PaymentMethodNavigation from '@/features/payment-method-navigation';
import PaymentMethodsList from '@/features/payment-methods-list';
import PaymentPoints from '@/features/payment-points';
import Support from '@/features/support';
import Validation, { ValidationError } from '@/lib/forms/validation';
import { CurrencyCode } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import { Platform } from '@/services/configuration-service';
import {
    AutoTopupInfo,
    MapLink,
    PaymentMethod,
    SuggestedAmountView,
    Suggestion
} from '@/services/payment-info-service';
import { PaymentMethodViewItem } from '@/services/view-service';
import AmountInput from '@/shared/ui/domain/amount-input';
import RoundedSections from '@/shared/ui/layout/rounded-sections';

import InFramePaymentForm from '../in-frame-payment-form';

import * as UI from './ui';

import { AppData } from '../../../index';

type PaymentInfoViewProps = {
    defaultAmount: string;
    selectedMethodIndex: number | null;
    paymentMethods: PaymentMethod[];
    autoTopupInfo: AutoTopupInfo;
    suggestedAmountsView: SuggestedAmountView | null;
    country: Countries;
    currencyCode: CurrencyCode;
    services: AppData['services'];
    onSelectedMethodIndexChange: (index: number | null) => void;
    onPaymentMethod: (amount: string, method: PaymentMethod) => void;
    onAutoTopupClick: VoidFunction;
    onPaymentPointsClick: (mapSrc: string) => void;
} & WithTranslation;

type PaymentInfoState = {
    error?: ValidationError;
    showPaymentMethods: boolean;
    amount: string;
    isAutoTopupOpen: boolean;
    isIosKeyboardOpen: boolean;
    isAmountFromTips: boolean;
};

class PaymentInfo extends Component<PaymentInfoViewProps, PaymentInfoState> {
    private lastHeight: number;

    constructor(props: PaymentInfoViewProps) {
        super(props);

        this.lastHeight = window?.visualViewport?.height || 0;

        this.state = {
            error: undefined,
            showPaymentMethods: false,
            amount: this.props.defaultAmount,
            isAutoTopupOpen: false,
            isIosKeyboardOpen: false,
            isAmountFromTips: false
        };
    }

    public componentDidMount() {
        this.handleIosKeyboardBug();
    }

    /**
     * IOS Keyboard Bug
     * https://stackoverflow.com/questions/43833049/how-to-make-fixed-content-go-above-ios-keyboard
     * */
    private handleIosKeyboardBug = () => {
        if (!/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
            return;
        }

        if (!window.visualViewport) {
            return;
        }

        // Screen resize in ios means that is keyboard opened/closed
        window.visualViewport.addEventListener('resize', () => {
            const platform = this.props.services.configurationService.getPlatform();
            if (platform !== Platform.IOS) {
                return;
            }

            if (window?.visualViewport?.height !== this.lastHeight) {
                this.lastHeight = window?.visualViewport?.height || 0;

                const isIosKeyboardOpen = !this.state.isIosKeyboardOpen;
                // So we need put submit button above
                this.setState({ isIosKeyboardOpen });

                if (isIosKeyboardOpen) {
                    window.scrollTo(0, 0);
                }
            }
        });
    };

    private handleAmountChange = (value: string) => {
        this.setState({ error: undefined, amount: value });
    };

    handleAmountIsFromTips = (isAmountFromTips: boolean) => {
        this.setState({ isAmountFromTips });
    };

    private handleResetAmount = () => {
        this.setState({ error: this.state.error, amount: '0' });
    };

    private handleSuggestedAmountClick = ({ value, days, rides }: Suggestion ) => {
        const { services } = this.props;
        services.analyticService.event('processing.topup_pay.amount_click', {
            amount: value.toString(),
            days,
            rides,
        });

        this.handleAmountChange(value.toString());
    };

    private handleSelectClick = () => {
        this.setState((prevState) => ({
            showPaymentMethods: !prevState.showPaymentMethods
        }));
    };

    private handleMethodSelect = (index: number) => {
        const { paymentMethods, onPaymentMethod } = this.props;
        const { amount } = this.state;
        this.props.onSelectedMethodIndexChange(index);
        this.setState({
            showPaymentMethods: false
        });

        const method = paymentMethods[index];

        const amountLimitError = this.validateAmountLimits(amount, method);

        if (amountLimitError) {
            this.setState({ error: amountLimitError });

            return;
        }

        onPaymentMethod(amount, method);
    };

    private handleMethodDelete = async (index: number) => {
        const { paymentMethods, selectedMethodIndex } = this.props;

        if (selectedMethodIndex === index) {
            this.props.onSelectedMethodIndexChange(null);
        }

        const methodToDelete = paymentMethods[index];

        this.props.services.analyticService.event('processing.topup_saved_card_delete.click', undefined);

        if (!methodToDelete || !methodToDelete.isRemovable) {
            const error = 'Method to delete has bad index or cannot be deleted';
            Sentry.captureMessage(error);

            return;
        }

        try {
            await this.props.services.paymentInfoService.deleteMethod(methodToDelete);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    private handleMethodsClose = () => {
        this.setState({ showPaymentMethods: false });
    };

    private handleOpenEmail = (email: string) => {
        this.props.services.navigatorService.openEmail(email);
    };

    private handleOpenPhone = (phone: string) => {
        this.props.services.navigatorService.openPhone(phone);
    };

    private handleOpenSupportChat = () => {
        this.props.services.navigatorService.openSupportChat();
    };

    private handlePaymentPointsClick = (map?: { link: MapLink, src: string }) => {
        if (!map) {
            return;
        }

        if (map.link === MapLink.FRAME) {
            this.props.onPaymentPointsClick(map.src);

            return;
        }

        this.props.services.navigatorService.goOutside(map.src);
    };

    private validateAmount = (value: string): ValidationError => {
        return Validation.validate(value?.toString(), [
            Validation.required,
        ]);
    };

    private validateAmountLimits = (amount: string, method: { limits: { min: number, max: number } }) => {
        return Validation.validate(amount, [Validation.minMaxValue(
            method.limits.min,
            method.limits.max,
            this.props.currencyCode,
        )]);
    };

    private handleSubmit = async () => {
        const {
            services,
            onPaymentMethod,
            paymentMethods,
            selectedMethodIndex,
        } = this.props;
        const { amount } = this.state;
        services.logsService.write(`handle press TopUp ${amount}`);
        const amountForValidation = amount?.trim() === '0'? '' : amount;

        if (selectedMethodIndex === null) {
            const validationError = this.validateAmount(amountForValidation);

            if (validationError) {
                this.setState({ error: validationError });

                return;
            }

            this.setState({ showPaymentMethods: true });

            return;
        }

        const method = paymentMethods[selectedMethodIndex];
        const amountLimitError = this.validateAmountLimits(amount, method);
        if (amountLimitError) {
            this.setState({ error: amountLimitError });

            return;
        }

        onPaymentMethod(amount, method);
        services.analyticService.event('processing.topup_pay_securely.click', {
            amount,
            amount_is_from_tips: this.state.isAmountFromTips,
        });

        return;
    };

    private getSelectedMethod = (items: PaymentMethodViewItem[]): PaymentMethodViewItem | undefined => {
        const { selectedMethodIndex } = this.props;

        if (selectedMethodIndex !== null) {
            return items[selectedMethodIndex];
        }

        return undefined;
    };

    private getPaymentContentSection = (
        selectedMethod?: PaymentMethodViewItem,
    ) => {
        if (selectedMethod?.inFrameShow) {
            const { country, services } = this.props;

            return (
                <>
                    <UI.InFramePaymentFormWrapper>
                        <InFramePaymentForm
                            country={country}
                            services={services}
                            method={selectedMethod.originalMethod}
                        />
                    </UI.InFramePaymentFormWrapper>

                    {
                        selectedMethod.originalMethod.support.map ? (
                            <UI.PaymentPointsWrapper>
                                <PaymentPoints
                                    onClick={
                                        () => this.handlePaymentPointsClick(selectedMethod.originalMethod.support.map)
                                    }
                                />
                            </UI.PaymentPointsWrapper>
                        ) : null
                    }
                </>
            );
        }

        return (
            <UI.AmountInputWrapper>
                <AmountInput
                    error={this.state.error?.message}
                    symbol={this.props.currencyCode}
                    country={this.props.country}
                    amount={this.state.amount}
                    suggestedAmountsView={this.props.suggestedAmountsView}
                    onChange={this.handleAmountChange}
                    onAmountIsFromTips={this.handleAmountIsFromTips}
                    onResetAmount={this.handleResetAmount}
                    onSuggestedAmountClick={this.handleSuggestedAmountClick}
                    onSubmitByKeyboard={this.handleSubmit}
                />
            </UI.AmountInputWrapper>
        );
    };

    private getAdditionalContentSection = (
        selectedMethod?: PaymentMethodViewItem,
    ) => {
        const { t, currencyCode } = this.props;

        if (selectedMethod?.inFrameShow) {
            return (
                <UI.SupportWrapper>
                    <Support
                        phones={selectedMethod.originalMethod.support.phones}
                        emails={selectedMethod.originalMethod.support.emails}
                        onOpenEmail={this.handleOpenEmail}
                        onOpenPhone={this.handleOpenPhone}
                        onOpenSupportChat={this.handleOpenSupportChat}
                    />
                </UI.SupportWrapper>
            );
        }

        return (
            <UI.AdditionalContent>
                <UI.FlexSpacer>
                    {
                        this.props.services.paymentInfoService.hasYunoSavedCards() ? (
                            <AutoTopupButton
                                currencyCode={currencyCode}
                                autoTopupInfo={this.props.autoTopupInfo}
                                onClick={this.props.onAutoTopupClick}
                            />
                        ) : null
                    }
                </UI.FlexSpacer>

                <UI.SubmitWrapper>
                    <Button
                        data-id='submit'
                        type='submit'
                        onClick={this.handleSubmit}
                    >
                        { t('views.PaymentInfo.submitMethod') }
                    </Button>
                </UI.SubmitWrapper>
            </UI.AdditionalContent>
        );
    };

    public render() {
        const { t, currencyCode, paymentMethods } = this.props;
        const { viewService } = this.props.services;

        const paymentMethodItems = viewService.adaptPaymentMethods(
            paymentMethods,
            currencyCode,
            this.props.country,
        );

        const selectedMethod = this.getSelectedMethod(paymentMethodItems);
        const { showPaymentMethods } = this.state;

        return (
            <>
                <RoundedSections
                    top={
                        <PaymentMethodNavigation
                            paymentMethod={selectedMethod}
                            onSelectClick={this.handleSelectClick}
                        />
                    }
                    middle={this.getPaymentContentSection(selectedMethod)}
                    foldBottom={this.state.isIosKeyboardOpen}
                    bottom={this.getAdditionalContentSection(selectedMethod)}
                />

                <PaymentMethodsList
                    open={showPaymentMethods}
                    title={t('views.PaymentInfo.methodsListTitle')}
                    selectedMethodIndex={this.props.selectedMethodIndex}
                    paymentMethods={paymentMethodItems}
                    onMethodSelect={this.handleMethodSelect}
                    onMethodDelete={this.handleMethodDelete}
                    onClose={this.handleMethodsClose}
                />
            </>
        );
    }
}

export default withTranslation()(PaymentInfo);
