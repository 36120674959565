import { Component, createRef, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Price, { CurrencyPlacement } from '@/lib/format/price';
import { currentTargetValue } from '@/lib/forms/selectors';
import { Countries } from '@/lib/locale/countries';
import { AMOUNT_ID } from '@/lib/payments/forms/indentificators';
import { SuggestedAmountView, Suggestion } from '@/services/payment-info-service';

import SuggestedAmount from './suggested-amount';
import * as UI from './ui';

import Resources from '../../../../resources';



type AmountInputProps = {
    symbol?: string;
    country: Countries;
    error?: string;
    amount: string;
    suggestedAmountsView: SuggestedAmountView | null;
    onChange: (value: string) => void;
    onAmountIsFromTips: (isAmountFromTips: boolean) => void;
    onResetAmount: VoidFunction;
    onSuggestedAmountClick: (suggestion: Suggestion) => void;
    onSubmitByKeyboard: VoidFunction;
} & WithTranslation;

const DEFAULT_DIGIT = '0';

class AmountInput extends Component<AmountInputProps> {
    private inputRef: RefObject<HTMLInputElement>;
    private currencyPlacement: CurrencyPlacement;

    constructor(props: AmountInputProps) {
        super(props);

        this.inputRef = createRef();
        this.currencyPlacement = this.getCurrencyPlacement(props.country);
    }

    public componentDidMount = () => {
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('beforeinput', (e: InputEvent) => {
                if (e.inputType === 'insertLineBreak') {
                    this.props.onSubmitByKeyboard();
                }
            });

            if (this.props.amount === '') {
                this.props.onChange(DEFAULT_DIGIT);
            }
        }

        window?.document.addEventListener('click', this.handleDocumentClick);
    };

    public componentDidUpdate = () => {
        this.unselectSymbol();
    };

    public componentWillUnmount = () => {
        window?.document.removeEventListener('click', this.handleDocumentClick);
    };

    private getCurrencyPlacement = (country: Countries) => {
        switch (country) {
            case Countries.Kazakhstan:
                return CurrencyPlacement.Postfix;
            default:
                return CurrencyPlacement.Prefix;
        }
    };

    private handleDocumentClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
            const isChipButton = e.target.dataset.id === Resources.test.chipButton;
            const isAmountInput = e.target.id === AMOUNT_ID;

            if (isChipButton || isAmountInput) {
                return;
            } else {
                this.inputRef?.current?.blur();
            }
        }

        if (this.props.amount === '') {
            this.props.onResetAmount();
        }
    };

    private handleChange = (value: string) => {
        value = value.replace(/\D/g, '');

        while (value.startsWith(DEFAULT_DIGIT)) {
            value = value.substring(1);
        }

        this.props.onAmountIsFromTips(false);
        this.props.onChange(value);
    };

    private handleSuggestedAmountClick = (suggestion: Suggestion) => {
        this.props.onAmountIsFromTips(true);
        this.props.onSuggestedAmountClick(suggestion);
    };

    private getNewCaretPosition = (config: {
        caretStart: number,
        caretEnd: number,
        valueLength: number,
        symbolLength: number,
        currencyPlacement: CurrencyPlacement
    }) => {
        const {
            caretStart,
            caretEnd,
            symbolLength,
            valueLength,
            currencyPlacement
        } = config;

        const shiftLength = symbolLength === 0 ? 0 : symbolLength + 1;
        let borderPosition: number;

        if ( currencyPlacement === CurrencyPlacement.Postfix ) {
            borderPosition = valueLength - shiftLength;
        } else {
            borderPosition = valueLength;
        }

        if (borderPosition <= 0) {
            return {
                start: 0,
                end: 0
            };
        }

        if (currencyPlacement === CurrencyPlacement.Prefix) {
            if (caretStart < shiftLength) {
                return {
                    start: shiftLength,
                    end: shiftLength
                };
            }

            if (caretEnd > borderPosition) {
                return {
                    start: caretStart,
                    end: borderPosition
                };
            }
        } else {
            if (caretStart > borderPosition) {
                return {
                    start: borderPosition,
                    end: borderPosition
                };
            }

            if (caretEnd > borderPosition) {
                return {
                    start: caretStart,
                    end: borderPosition
                };
            }
        }

        return {
            start: caretStart,
            end: caretEnd
        };
    };

    private unselectSymbol = () => {
        if (!this.inputRef.current) {
            return;
        }

        const element = this.inputRef.current;

        const positionStart = element.selectionStart;
        const positionEnd = element.selectionEnd;

        if (positionStart === null || positionEnd === null) {
            return;
        }

        const onlyAmount = element.value.trim().replace(/\D/g, '');
        if (onlyAmount === DEFAULT_DIGIT) {
            if (this.currencyPlacement === CurrencyPlacement.Postfix) {
                element.setSelectionRange(1, 1);
            } else {
                const valueLength = element.value.length;
                element.setSelectionRange(valueLength, valueLength);
            }

            return;
        }

        const { start, end } = this.getNewCaretPosition({
            caretStart: element.selectionStart ?? 0,
            caretEnd: element.selectionEnd ?? 0,
            symbolLength: this.props.symbol?.length ?? 0,
            valueLength: element.value.length,
            currencyPlacement: this.currencyPlacement
        });

        element.setSelectionRange(start, end);
    };

    private handleClick = () => {
        this.unselectSymbol();
    };

    private handleFocus = () => {
        this.unselectSymbol();
    };

    private getDisplayValue = () => {
        const { symbol, amount } = this.props;

        return new Price(amount, symbol ?? '').format({
            currencyPlacement: this.currencyPlacement,
            withCurrency: true
        });
    };

    public render() {
        const { error, suggestedAmountsView } = this.props;
        const hasError = Boolean(error);

        return (
            <UI.Wrapper>
                <UI.AmountTitle>
                    { this.props.t('shared.ui.domain.AmountInput.title') }
                </UI.AmountTitle>

                <UI.InputWrapper>
                    <UI.InputContent>
                        <UI.Input
                            ref={this.inputRef}
                            autoFocus
                            $error={hasError}
                            id={AMOUNT_ID}
                            data-id={AMOUNT_ID}
                            inputMode='numeric'
                            placeholder=''
                            pattern='\d*'
                            value={this.getDisplayValue()}
                            onFocus={this.handleFocus}
                            onClick={this.handleClick}
                            onChange={currentTargetValue(this.handleChange)}
                        />
                    </UI.InputContent>

                    {
                        hasError ? (
                            <UI.Error data-id={`${AMOUNT_ID}-validation-error`}>
                                {error}
                            </UI.Error>
                        ) : null
                    }
                </UI.InputWrapper>

                <SuggestedAmount
                    suggestedAmountsView={suggestedAmountsView}
                    onSuggestedAmountClick={this.handleSuggestedAmountClick}
                    show={!this.props.error}
                />
            </UI.Wrapper>
        );
    }
}

export default withTranslation()(AmountInput);
