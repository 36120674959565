import i18n from 'i18next';
import { FC } from 'react';

import Price from '@/lib/format/price';
import { SuggestedAmountView, Suggestion } from '@/services/payment-info-service';
import { SuggestionViewType } from '@/services/payment-provider-service/api/init';

import * as UI from './ui';

import Resources from '../../../../../resources';

type SuggestedAmountProps = {
    suggestedAmountsView: SuggestedAmountView | null;
    onSuggestedAmountClick: (suggestion: Suggestion) => void;
    show: boolean;
}

const getChipsDescription = (suggestion: Suggestion, viewType?: SuggestionViewType) => {
    if (viewType === SuggestionViewType.WITH_DAYS) {
        return i18n.t('shared.ui.domain.SuggestedAmount.typeWithDaysDescription', { count: suggestion.days });
    }

    if (viewType === SuggestionViewType.WITH_RIDES) {
        return i18n.t('shared.ui.domain.SuggestedAmount.typeWithRidesDescription', { count: suggestion.rides });
    }

    return null;
};

const SuggestedAmount: FC<SuggestedAmountProps> = (props) => {
    const { suggestedAmountsView, show, onSuggestedAmountClick } = props;
    const handleSuggestedAmountClick = ({ value, days, rides }: Suggestion) => {
        if (suggestedAmountsView?.viewType === SuggestionViewType.WITH_DAYS) {
            onSuggestedAmountClick({ value: value, days: days });

            return;
        }

        if (suggestedAmountsView?.viewType === SuggestionViewType.WITH_RIDES) {
            onSuggestedAmountClick({ value: value, rides: rides });

            return;
        }
        onSuggestedAmountClick({ value: value });
    };

    if (suggestedAmountsView?.suggestedAmounts && suggestedAmountsView.suggestedAmounts.length > 1) {

        return (
            <UI.ChipsWrapper>
                {
                    suggestedAmountsView.suggestedAmounts.map((suggestion, index) => {
                        const chipsDescription = getChipsDescription(suggestion, suggestedAmountsView.viewType);
                        const price = new Price(suggestion.value, '').format();

                        return (
                            <UI.Chip
                                key={index}
                                data-id={Resources.test.chipButton}
                                onClick={() => handleSuggestedAmountClick(suggestion)}
                            >
                                <UI.Amount>
                                    {price}
                                </UI.Amount>

                                {chipsDescription ? <UI.Description>{chipsDescription}</UI.Description> : null}
                            </UI.Chip>
                        );
                    })
                }
            </UI.ChipsWrapper>
        );
    }

    return <UI.ChipsEmptySpace $show={show} />;
};

export default SuggestedAmount;