import { FC, useEffect, useState } from 'react';

import { GQLErrorType } from '@/lib/errors/graphql-error';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { getCountryCode } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import { FormValues } from '@/lib/payments/forms';
import {
    YunoGenerateTokenOptions,
    YunoSecureFields
} from '@/lib/payments/integrations/yuno/types';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import YunoNewBankCardView from './new-card';
import YunoSavedCardView from './saved-card';

const YunoBankCardController: FC<PaymentFormData> = ({
    store,
    paymentInfo,
    services,
    onHideGoBack,
}) => {
    const [yunoSecureFields, setYunoSecureFields] = useState<YunoSecureFields|undefined>();

    useEffect(() => {
        onHideGoBack();

        const sdk = new SDK(services.logsService);
        sdk.get(PaymentProviderName.Yuno).then((initialize => {
            if (!store.selectedMethod.session?.yuno?.apiKey) {
                services.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const yunoInstance = initialize(store.selectedMethod.session.yuno.apiKey);
            services.topupService.setYunoInstance(yunoInstance);

            const secureFields = yunoInstance.secureFields({
                countryCode: getCountryCode(paymentInfo.country),
                checkoutSession: store.selectedMethod.session.yuno.sessionToken,
            });

            setYunoSecureFields(secureFields);
        }));
    }, []);

    const handleSubmit = async (
        values: FormValues
    ): Promise<GQLErrorType> => {
        if (!yunoSecureFields) {
            return;
        }

        const customer: YunoGenerateTokenOptions['customer'] = {};

        if (
            paymentInfo.country === Countries.Brazil ||
            paymentInfo.country === Countries.Colombia ||
            paymentInfo.country === Countries.Argentina
        ) {
            customer.document = {
                document_number: values.document,
            };
        }

        if (
            paymentInfo.country === Countries.Mexico ||
            paymentInfo.country === Countries.Peru ||
            paymentInfo.country === Countries.Chile ||
            paymentInfo.country === Countries.Panama
        ) {
            customer.email = values.email;
            customer.first_name = values.firstName;
            customer.last_name = values.lastName;
        }

        if (
            paymentInfo.country === Countries.Ecuador ||
            paymentInfo.country === Countries.Botswana ||
            paymentInfo.country === Countries.DominicanRepublic ||
            paymentInfo.country === Countries.Jamaica ||
            paymentInfo.country === Countries.Zimbabwe ||
            paymentInfo.country === Countries.Bolivia ||
            paymentInfo.country === Countries.Malaysia
        ) {
            // nothing
        }

        const creditCardPayload: YunoGenerateTokenOptions = store.selectedMethod.isRemovable ? {
            _: 'saved',
            vaultedToken: store.selectedMethod.session?.yuno?.vaultedToken ?? '',
            customer,
        } : {
            _: 'new',
            cardHolderName: values.cardHolderName,
            saveCard: true,
            customer
        };

        const oneTimeToken = await yunoSecureFields.generateToken(creditCardPayload);

        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            {
                ...values,
                oneTimeToken,
            },
            paymentInfo.country,
        );

        return;
    };

    if (!yunoSecureFields) {
        return <CenterLoader loading />;
    }

    {/*TODO https://indriver.atlassian.net/browse/PRC-2208*/}

    return store.selectedMethod.isRemovable ? (
        <YunoSavedCardView
            panValue={store.selectedMethod.methodName}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmit}
        />
    ) : (
        <YunoNewBankCardView
            secureFields={yunoSecureFields}
            formFields={store.selectedMethod.formFields}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            onSubmit={handleSubmit}
        />
    );
};

export default YunoBankCardController;
