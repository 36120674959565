import i18n from 'i18next';

export enum CurrencyPlacement {
    Prefix = 'prefix',
    Postfix = 'postfix',
}

type FormatPriceOptions = {
    currencyPlacement?: CurrencyPlacement;
    withCurrency?: boolean;
}

class Price {
    private amount: number | string | undefined;
    private currency: string;

    constructor(amount: number | string | undefined, currency: string) {
        this.amount = amount;
        this.currency = currency;
    }

    private getLocale = () => {
        switch (i18n.language) {
            case 'kz-KZ': {
                return 'kz-KZ';
            }
            default: {
                return 'en-US';
            }

        }
    };

    private makeHumanReadableAmount = (value?: number | string): string => {
        if (!value) {
            return '0';
        }

        if (typeof value === 'string') {
            return parseInt(value, 10).toLocaleString(this.getLocale());
        }

        return value.toLocaleString(this.getLocale());
    };

    private addCurrency = (value: string, currency: string, currencyPlacement: CurrencyPlacement) => {
        switch (currencyPlacement) {
            case CurrencyPlacement.Postfix:
                return `${value}\u00A0${currency}`;
            default:
                return `${currency}\u00A0${value}`;
        }
    };

    public format(options?: FormatPriceOptions) {
        const readableAmount = this.makeHumanReadableAmount(this.amount);
        let withCurrency = true;
        let currencyPlacement: CurrencyPlacement = CurrencyPlacement.Prefix;

        if (this.currency === '') {
            withCurrency = false;
        }

        if (options) {
            if (options.withCurrency !== undefined) {
                withCurrency = options.withCurrency;
            }
            if (options.currencyPlacement !== undefined ) {
                currencyPlacement = options.currencyPlacement;
            }
        }

        if (withCurrency) {
            return this.addCurrency(readableAmount, this.currency, currencyPlacement);
        }

        return readableAmount;
    }
}

export default Price;
