import { getSessionErrorMessage } from '@/lib/errors/session';
import ErrorStatus from '@/lib/errors/status';
import { Countries } from '@/lib/locale/countries';
import { FormValues } from '@/lib/payments/forms';
import {
    BILLING_CITY_ID,
    CITY_ID,
    CONFIRMATION_CODE_ID,
    DOCUMENT_ID,
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID
} from '@/lib/payments/forms/indentificators';
import Credorax from '@/lib/payments/integrations/credorax';
import { YunoInstance } from '@/lib/payments/integrations/yuno/types';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import AnalyticsService from '@/services/analytics-service';
import NavigatorService from '@/services/navigator-service';
import PaymentInfoService, { PaymentMethod } from '@/services/payment-info-service';
import PaymentProviderService, { DataType } from '@/services/payment-provider-service';

import { WooppaySession } from '../../graphql/phone-balance/woopay';
import Resources from '../../resources';

// todo https://indriver.atlassian.net/browse/PRC-1375
class TopupService {
    private paymentInfoService: PaymentInfoService;
    private analyticService: AnalyticsService;
    private navigatorService: NavigatorService;
    private process: PaymentProviderService['process'];
    private yunoInstance?: YunoInstance;
    private wooppaySession?: WooppaySession;

    constructor(
        paymentProviderService: PaymentProviderService,
        paymentInfoService: PaymentInfoService,
        analyticService: AnalyticsService,
        navigatorService: NavigatorService,
    ) {
        this.analyticService = analyticService;
        this.paymentInfoService = paymentInfoService;
        this.navigatorService = navigatorService;
        this.process = paymentProviderService.process.bind(paymentProviderService);
    }

    public async topUp(
        amount: string,
        method: PaymentMethod,
        values: FormValues,
        country: Countries,
    ) {
        this.analyticService.event('processing.topup_pay.click', {
            payment_option: method.paymentType,
            provider: method.providerName,
            sum: parseInt(amount, 10),
        });

        // This is a smooth migration for formless payments
        // Now it works only for Payrails
        // https://indriver.atlassian.net/browse/PRC-2181
        if (method.providerName === PaymentProviderName.Payrails) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.topUpWithPayrailsCard(amount, method, values);
            }

            return this.topupPayrailsGenericMethod(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Credorax) {
            return this.topUpWithCredorax(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Dlocal) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.createCardDlocal(amount, method, values, country);
            }

            if (method.paymentType === PaymentType.WALLET) {
                return this.topUpWithDlocalWallet(amount, method, values);
            }

            if (method.paymentType === PaymentType.CASH) {
                return this.createDlocalCash(amount, method, values);
            }

            if (method.paymentType === PaymentType.BANK_TRANSFER) {
                return this.createDlocalBankTransfer(amount, method, values, country);
            }
        }

        if (method.providerName === PaymentProviderName.Yuno) {
            return this.topUpWithYuno(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Bcel) {
            return this.topupWithBCEL(amount, values);
        }

        if (method.providerName === PaymentProviderName.OneVision) {
            return this.topupWithOneVision(amount, method, values);
        }

        if (method.providerName === PaymentProviderName.Simpaisa) {
            return this.topupWithSimpaisa(amount, values);
        }

        if (method.providerName === PaymentProviderName.Iyzico) {
            if (method.paymentType === PaymentType.WALLET) {
                return this.topupWithIyzicoWallet(amount, values);
            }

            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.topupWithIyzicoCard(amount, values);
            }
        }

        if (method.providerName === PaymentProviderName.Izi) {
            if (method.paymentType === PaymentType.CASH) {
                return this.topupWithIziCash(amount, values);
            }

            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.topUpWithIziCreditCard(amount, values);
            }
        }

        if (method.providerName === PaymentProviderName.Fawry) {
            if (method.paymentType === PaymentType.CASH) {
                return this.topupWithFawryCash(amount, values);
            }
        }

        if (method.providerName === PaymentProviderName.Slick) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.topupWithSlick(amount);
            }
        }

        if (method.providerName === PaymentProviderName.Wooppay) {
            if (method.paymentType === PaymentType.PHONE_BALANCE) {
                return this.topupWooppay(values);
            }
        }

        if (method.providerName === PaymentProviderName.Nuvei) {
            if (method.paymentType === PaymentType.BANK_CARD) {
                return this.authorizeWithNuvei(values);
            }
        }

        return undefined;
    }

    private topupWithOneVision = (amount: string, method: PaymentMethod, values: FormValues) => {
        return this.process(async (req) => {
            if (!method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topUpWithOneVision({
                amount: parseInt(amount, 10),
                phone: values.phone,
                paymentMethodCode: method.paymentMethodCode,
            });

            if (data?.topupWithOneVision.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithOneVision.redirectUrl);

                return;
            }

            if (data?.topupWithOneVision.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithSimpaisa = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithSimpaisa({
                phone: values.phone,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithSimpaisa.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithSimpaisa.redirectUrl);

                return;
            }

            if (data?.topupWithSimpaisa.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithIyzicoWallet = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIyzicoWallet({
                amount: parseInt(amount, 10),
                document: values[DOCUMENT_ID],
                city: values[CITY_ID],
                address: values[FULL_ADDRESS_ID],
                billingCity: values[BILLING_CITY_ID] || values[CITY_ID],
                billingAddress: values[FULL_BILLING_ADDRESS_ID] || values[FULL_ADDRESS_ID],
            });

            if (data) {
                const redirect = data.topupWithIyzicoWallet.redirectUrl;
                this.navigatorService.goOutside(redirect);

                return;
            }

            await this.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    private topupWithIyzicoCard = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIyzicoCard({
                amount: parseInt(amount, 10),
                document: values[DOCUMENT_ID],
                city: values[CITY_ID],
                address: values[FULL_ADDRESS_ID],
                billingCity: values[BILLING_CITY_ID] || values[CITY_ID],
                billingAddress: values[FULL_BILLING_ADDRESS_ID] || values[FULL_ADDRESS_ID],
            });

            if (data) {
                const redirect = data.topupWithIyzicoCard.redirectUrl;
                this.navigatorService.goOutside(redirect);

                return;
            }

            await this.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    private topupWithIziCash = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithIziCash({
                document: values.document,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithIziCash.voucherReference) {
                this.paymentInfoService.setIziCashData({
                    voucherReference: data.topupWithIziCash.voucherReference,
                });
            }
        });
    };

    private topUpWithIziCreditCard = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topUpWithIziCreditCard({
                document: values.document,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithIziCard.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithIziCard.redirectUrl);

                return;
            }

            if (data?.topupWithIziCard.redirectUrl === '') {
                await this.navigatorService.showFinalPage('error');
            }
        });
    };

    private topupWithFawryCash = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithFawryCash({
                email: values.email,
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithFawryCash) {
                this.paymentInfoService.setFawryCashData(data.topupWithFawryCash);
            }
        });
    };

    private topupWithSlick = (amount: string) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithSlick({
                amount: parseInt(amount, 10),
            });

            if (data?.topupWithSlick.redirectURL) {
                this.navigatorService.goOutside(data.topupWithSlick.redirectURL);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topupWooppay = async (values: FormValues) => {
        return this.process(async (req) => {
            if (!this.wooppaySession) {
                return;
            }

            const { data } = await req.topupWooppay({
                session: this.wooppaySession,
                [CONFIRMATION_CODE_ID]: values[CONFIRMATION_CODE_ID],
            });

            if (data?.topupWooppay.result) {
                this.navigatorService.showFinalPage('success');

                return;
            }

            this.navigatorService.showFinalPage('error');
        });
    };

    private authorizeWithNuvei = async (values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.authorizeWithNuvei({
                email: values.email,
                paymentID: values.paymentID,
                providerSession: values.providerSession,
                cardToken: values.cardToken,
                cardHolderName: values.cardHolderName,
            });

            if (data) {
                await this.navigatorService.showFinalPage('success');

                return;
            }
        });
    };

    private topupWithBCEL = (amount: string, values: FormValues) => {
        return this.process(async (req) => {
            const { data } = await req.topupWithBCEL({
                amount: parseInt(amount, 10),

                email: values.email,
                city: values.city,
                houseNumber: values.houseNumber,
                state: values.state,
                street: values.street,
                zipCode: values.zipCode,
            });

            if (data?.topupWithBCEL?.parameters) {
                const parameters = data.topupWithBCEL.parameters;

                const form = document.createElement('form');
                form.method = 'POST';
                form.action = Resources.BCEL_ENDPOINT_URL;
                form.style.display = 'none';

                for (const { key, value } of parameters) {
                    const hidenInput = document.createElement('input');
                    hidenInput.type = 'hidden';
                    hidenInput.name = key;
                    hidenInput.value = value;
                    form.appendChild(hidenInput);
                }

                document.body.appendChild(form);
                form.submit();

                return;
            }

        });
    };

    private topUpWithPayrailsCard = (amount: string, method: PaymentMethod, values: FormValues) => {
        return this.process(async (req) => {
            if (!method.session?.payrails) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            if (method.isInstrument) {
                const { data } = await req.topUpWithSavedPayrailsCard({
                    session: {
                        sessionId: method.session.payrails.session,
                        transactionId: method.session.payrails.paymentId,
                        amount,
                    },
                    instrumentID: method.session.payrails.instrumentId,
                    amount: parseInt(amount, 10),
                    document: values[DOCUMENT_ID],
                    securityCodeToken: values.encryptedData,
                });

                if (data?.topupWithSavedCardPayrails.redirectUrl) {
                    this.navigatorService.goOutside(
                        data.topupWithSavedCardPayrails.redirectUrl,
                    );

                    return;
                }

                this.navigatorService.showFinalPage('success');
            }

            const payrailsData = values?.numberToken ? {
                numberToken: values.numberToken,
                securityCodeToken: values.securityCodeToken,
                vaultedToken: values.vaultedToken,
            } : {
                collectData: {
                    vaultProviderConfigId: values.vaultProviderConfigId,
                    encryptedData: values.encryptedData,
                },
            };

            const { data } = await req.topUpWithPayrailsCard({
                session: {
                    sessionId: method.session.payrails.session,
                    transactionId: method.session.payrails.paymentId,
                    amount,
                },
                ...payrailsData,
                document: values[DOCUMENT_ID],
            });

            if (data?.topupWithCardPayrails.redirectUrl) {
                this.navigatorService.goOutside(
                    data.topupWithCardPayrails.redirectUrl,
                );

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topupPayrailsGenericMethod = (amount: string, method: PaymentMethod, values: FormValues) => {
        return this.process(async (req) => {
            if (!method.session?.payrails || !method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topupPayrailsGenericMethod({
                session: {
                    sessionId: method.session.payrails.session,
                    transactionId: method.session.payrails.paymentId,
                    amount: amount,
                },
                paymentMethodType: method.paymentType,
                paymentMethodCode: method.paymentMethodCode,
                // todo https://indriver.atlassian.net/browse/PRC-1375
                document: values.document || '',
            });

            if (data?.topupPayrailsGenericMethod.redirectUrl) {
                this.navigatorService.goOutside(data.topupPayrailsGenericMethod.redirectUrl);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };

    private topUpWithCredorax = async (amount: string, method: PaymentMethod, values: FormValues) => {
        const session = method.session?.credorax;

        const input = method.savedInstruments?.bankCard ? {
            amount: parseInt(amount, 10),
            firstName: values.firstName,
            lastName: values.lastName,
            /**
             * Backend asking send below empty strings because it is required fields
             */
            pKeyResponse: '',
            email: '',
            zipCode: '',
            city: '',
            street: '',
            houseNumber: '',
        } : {
            amount: parseInt(amount, 10),
            pKeyResponse: JSON.stringify(
                await Credorax.getPKey(
                    {
                        cardNumber: values.cardNumber,
                        expirationMonth: values.expirationMonth,
                        expirationYear: values.expirationYear,
                        cvv: values.cvv,
                        cardHolder: values.cardHolder,
                    },
                    session?.merchantID ?? '',
                    session?.staticKey ?? ''
                ),
            ),
            email: values.email,
            zipCode: values.zipCode,
            city: values.city,
            street: values.street,
            houseNumber: values.houseNumber,
            firstName: values.cardHolder.split(' ')[0],
            lastName: values.cardHolder.split(' ')[1],
        };

        return this.process(async (req) => {
            await req.topUpWithCredorax(input);

            this.navigatorService.showFinalPage('success');
        });
    };

    private createCardDlocal = (amount: string, method: PaymentMethod, values: FormValues, country: Countries) => {
        let input: DataType['CreateCardDlocalInput'] = {
            token: values.token,
            amount: parseInt(amount, 10),
            cardHolderName: values.cardHolderName,
            email: values.email,
            document: values.document,
        };

        if (country === Countries.India || country === Countries.SouthAfrica) {
            input.state = values.state;
            input.city = values.city;
            input.zipCode = values.zipCode;
            input.street = values.street;
            input.houseNumber = values.houseNumber;
        }

        if (method.savedInstruments?.bankCard) {
            input = {
                amount: parseInt(amount, 10),
                document: values.document,
                email: values.email,
                token: country === Countries.India ? values.token : undefined,
            };
        }

        return this.process(async (req) => {
            const { data } = await req.createCardDlocal(input);

            if (data?.createCardDlocal.threeDSecureRedirectUrl === '') {
                await this.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.createCardDlocal.threeDSecureRedirectUrl) {
                this.navigatorService.goOutside(data?.createCardDlocal?.threeDSecureRedirectUrl);
            }
        });
    };

    private topUpWithDlocalWallet = (amount: string, method: PaymentMethod, values: FormValues) => {
        return this.process(async (req) => {
            if (!method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topUpWithDlocalWallet({
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.paymentMethodCode,
            });

            if (data?.topupWithDlocalWallet?.redirectUrl === '') {
                this.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.topupWithDlocalWallet?.redirectUrl) {
                this.navigatorService.goOutside(data.topupWithDlocalWallet.redirectUrl);
            }
        });
    };

    private createDlocalCash = (amount: string, method: PaymentMethod, values: FormValues) => {
        return this.process(async (req) => {
            if (!method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.createDlocalCash({
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.paymentMethodCode,
            });

            if (data?.createCashDlocal?.redirectUrl === '') {
                await this.navigatorService.showFinalPage('success');
            }

            if (data?.createCashDlocal?.redirectUrl) {
                await this.navigatorService.goOutside(data.createCashDlocal.redirectUrl);
            }
        });
    };

    private createDlocalBankTransfer = (
        amount: string,
        method: PaymentMethod,
        values: FormValues,
        country: Countries
    ) => {
        return this.process(async (req) => {
            if (!method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const input: DataType['CreateBankTransferDlocalInput'] = {
                ...values,
                username: values.username,
                email: values.email,
                document: values.document,
                amount: parseInt(amount, 10),
                paymentMethodCode: method.paymentMethodCode,
            };

            // todo https://indriver.atlassian.net/browse/PRC-1375
            if (country === Countries.SouthAfrica) {
                input.state = values.state;
                input.city = values.city;
                input.zipCode = values.zipCode;
                input.street = values.street;
                input.houseNumber = values.houseNumber;
            }

            const { data } = await req.createDlocalBankTransfer(input);

            if (data?.createBankTransferDlocal?.redirectUrl === '') {
                await this.navigatorService.showFinalPage('success');
            }

            if (data?.createBankTransferDlocal?.redirectUrl) {
                await this.navigatorService.goOutside(data.createBankTransferDlocal.redirectUrl);
            }
        });
    };

    public setYunoInstance = (instance: YunoInstance) => {
        this.yunoInstance = instance;
    };

    public setWooppaySession = (session?: WooppaySession) => {
        this.wooppaySession = session;
    };

    private topUpWithYuno = (
        amount: string,
        method: PaymentMethod,
        values: FormValues,
    ) => {
        return this.process(async (req) => {
            if (!method.session?.yuno) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topUpWithYuno({
                methodName: method.session.yuno.methodName,
                vaultedToken: method.session.yuno.vaultedToken,
                session: method.session.yuno.sessionToken,
                paymentMethodCode: method.paymentMethodCode || '',
                amount: parseInt(amount, 10),
                email: values.email,
                oneTimeToken: values.oneTimeToken,
            });

            if (!this.yunoInstance) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            if (data?.topupWithYuno.sdkActionIsRequired) {
                this.yunoInstance.continuePayment();

                return;
            }

            this.navigatorService.showFinalPage('success');
        });
    };
}

export default TopupService;
