import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import HidableFormFieldView from '@/features/hidable-form-field-view';
import { GQLErrorType } from '@/lib/errors/graphql-error';
import { FormValues } from '@/lib/payments/forms';
import { PaymentType } from '@/lib/payments/payments';

import { PaymentFormData } from '../../index';

const GenericCheckoutController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { t } = useTranslation();

    let title = '';

    switch (store.selectedMethod.paymentType) {
        case PaymentType.CASH:
        case PaymentType.BANK_TRANSFER:
        case PaymentType.WALLET:
            title = t('views.PaymentMethods.getVoucherSubmitCaption');
            break;
        default:
            title = '';
    }
    const handleSubmit = async (values: FormValues): Promise<GQLErrorType> => {
        return services.topupService.topUp(
            store.amount,
            store.selectedMethod,
            values,
            paymentInfo.country,
        );
    };

    if (store.selectedMethod.hidableFormFields) {
        return (
            <HidableFormFieldView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={store.selectedMethod.formFields}
                hidableLabel={store.selectedMethod.hidableFormFields?.label}
                hidableFields={store.selectedMethod.hidableFormFields?.fields}
                onSubmit={handleSubmit}
            />
        );
    }

    return (
        <FormFieldView
            title={title}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmit}
        />
    );
};

export default GenericCheckoutController;
