export enum Countries {
    Brazil = 11,
    Mexico = 12,
    India = 14,
    Indonesia = 15,
    Salvador = 21,
    Colombia = 22,
    Guatemala = 23,
    Peru = 24,
    Chile = 25,
    Morocco = 30,
    Ecuador = 43,
    DominicanRepublic = 46,
    Bolivia = 71,
    Honduras = 75,
    Nicaragua = 76,
    Egypt = 153,
    SouthAfrica = 10,
    Panama = 77,
    CostaRica = 72,
    Nigeria = 40,
    Kenya = 8,
    Malaysia = 85,
    Pakistan = 60,
    Argentina = 13,
    Kazakhstan = 2,
    Jamaica = 54,
    Thailand = 19,
    Algeria = 165,
    Tanzania = 9,
    Botswana = 50,
    Zimbabwe = 70,
    Nepal = 86,
    Bangladesh = 81,
    Turkey = 84,
    Tunisia = 31,
    Philippines = 16,
    Kyrgyzstan = 5,
    Laos = 104
}
