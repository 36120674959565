import Resources from '../../resources';

import { Countries } from './countries';

export type CurrencyCode =
    | 'MXN'
    | 'BOB'
    | 'COP'
    | 'BRL'
    | 'USD'
    | 'PEN'
    | 'DOP'
    | 'CLP'
    | 'EGP'
    | 'Rp'
    | 'INR'
    | 'SVC'
    | 'NIO'
    | 'GTQ'
    | 'HNL'
    | 'KES'
    | 'NGN'
    | 'ZAR'
    | 'MAD'
    | 'PKR'
    | 'RM'
    | 'CRC'
    | 'PAB'
    | 'ARS'
    | '₸'
    | 'JMD'
    | 'THB'
    | 'TZS'
    | 'DZD'
    | 'BWP'
    | 'NPR'
    | 'TRY'
    | 'BDT'
    | 'TND'
    | 'PHP'
    | 'KGS'
    | 'LAK'
    | 'CURRENCY';

export const DEFAULT_LANG = 'en';

/**
 * @link {https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes}
 * A-2
 */
export type CountryCode =
    | 'MX'
    | 'BO'
    | 'CO'
    | 'BR'
    | 'EC'
    | 'PE'
    | 'DO'
    | 'CL'
    | 'EG'
    | 'ID'
    | 'IN'
    | 'SV'
    | 'NI'
    | 'GT'
    | 'HN'
    | 'KE'
    | 'NG'
    | 'ZA'
    | 'MA'
    | 'PK'
    | 'MY'
    | 'CR'
    | 'PA'
    | 'AR'
    | 'KZ'
    | 'JM'
    | 'TH'
    | 'TZ'
    | 'DZ'
    | 'ZW'
    | 'BW'
    | 'NP'
    | 'TR'
    | 'BD'
    | 'TN'
    | 'PH'
    | 'KG'
    | 'LA'

type CommonLocaleInfo = Record<Countries, [CountryCode, CurrencyCode, string, string]>

export const COMMON_LOCALE_INFO: CommonLocaleInfo = {
    [Countries.Mexico]: ['MX', 'MXN', Resources.files.documents.mexico, 'es'],
    [Countries.Bolivia]: ['BO', 'BOB', Resources.files.documents.bolivia, 'es'],
    [Countries.Colombia]: ['CO', 'COP', Resources.files.documents.colombia, 'es'],
    [Countries.Brazil]: ['BR', 'BRL', Resources.files.documents.brazil, 'pt'],
    [Countries.Ecuador]: ['EC', 'USD', Resources.files.documents.ecuador, 'es'],
    [Countries.Peru]: ['PE', 'PEN', Resources.files.documents.peru, 'es'],
    [Countries.DominicanRepublic]: ['DO', 'DOP', Resources.files.documents.dominicanrepublic, 'es'],
    [Countries.Chile]: ['CL', 'CLP', Resources.files.documents.chile, 'es'],
    [Countries.Egypt]: ['EG', 'EGP', Resources.files.documents.egypt, 'ar'],
    [Countries.Indonesia]: ['ID', 'Rp', Resources.files.documents.indonesia, 'id'],
    [Countries.India]: ['IN', 'INR', Resources.files.documents.india, 'hi'],
    [Countries.Salvador]: ['SV', 'USD', Resources.files.documents.default, 'es'],
    [Countries.Nicaragua]: ['NI', 'NIO', Resources.files.documents.nicaragua, 'es'],
    [Countries.Guatemala]: ['GT', 'GTQ', Resources.files.documents.guatemala, 'es'],
    [Countries.Honduras]: ['HN', 'HNL', Resources.files.documents.honduras, 'es'],
    [Countries.Kenya]: ['KE', 'KES', Resources.files.documents.kenya, DEFAULT_LANG],
    [Countries.Nigeria]: ['NG', 'NGN', Resources.files.documents.nigeria, DEFAULT_LANG],
    [Countries.SouthAfrica]: ['ZA', 'ZAR', Resources.files.documents.southafrica, DEFAULT_LANG],
    [Countries.Morocco]: ['MA', 'MAD', Resources.files.documents.morocco, 'ar'],
    [Countries.Pakistan]: ['PK', 'PKR', Resources.files.documents.pakistan, 'ur'],
    [Countries.Malaysia]: ['MY', 'RM', Resources.files.documents.malaysia, 'ms'],
    [Countries.CostaRica]: ['CR', 'CRC', Resources.files.documents.costarica, 'es'],
    [Countries.Panama]: ['PA', 'PAB', Resources.files.documents.panama, 'es'],
    [Countries.Argentina]: ['AR', 'ARS', Resources.files.documents.argentina, 'es'],
    [Countries.Kazakhstan]: ['KZ', '₸', Resources.files.documents.kazakhstan, 'kk'],
    [Countries.Jamaica]: ['JM', 'JMD', Resources.files.documents.default, DEFAULT_LANG],
    [Countries.Thailand]: ['TH', 'THB', Resources.files.documents.thailand, 'th'],
    [Countries.Tanzania]: ['TZ', 'TZS', Resources.files.documents.tanzania, DEFAULT_LANG],
    [Countries.Algeria]: ['DZ', 'DZD', Resources.files.documents.algeria, 'fr'],
    [Countries.Zimbabwe]: ['ZW', 'USD', Resources.files.documents.zimbabwe, DEFAULT_LANG],
    [Countries.Botswana]: ['BW', 'BWP', Resources.files.documents.default, DEFAULT_LANG],
    [Countries.Nepal]: ['NP', 'NPR', Resources.files.documents.default, DEFAULT_LANG],
    [Countries.Turkey]: ['TR', 'TRY', Resources.files.documents.turkey, DEFAULT_LANG],
    [Countries.Bangladesh]: ['BD', 'BDT', Resources.files.documents.default, 'bn'],
    [Countries.Tunisia]: ['TN', 'TND', Resources.files.documents.default, 'ar'],
    [Countries.Philippines]: ['PH', 'PHP', Resources.files.documents.default, DEFAULT_LANG],
    [Countries.Kyrgyzstan]: ['KG', 'KGS', Resources.files.documents.default, DEFAULT_LANG],
    [Countries.Laos]: ['LA', 'LAK', Resources.files.documents.default, 'lo'],
};

export const getCountryCode = (country: Countries): CountryCode => {
    return COMMON_LOCALE_INFO[country][0];
};

export const getCurrencyCode = (country: Countries): CurrencyCode => {
    return COMMON_LOCALE_INFO[country] && COMMON_LOCALE_INFO[country][1] || 'CURRENCY';
};

export const getIdentificationDocumentPicture = (country: Countries): string => {
    return COMMON_LOCALE_INFO[country][2] || Resources.files.documents.default;
};

export const getCountryLanguage = (country: Countries): string => {
    return COMMON_LOCALE_INFO[country][3] || DEFAULT_LANG;
};
